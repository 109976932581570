import { SolarDataType } from "@/models/solar-data-type.enum";
import { ServiceBase } from "./service-base";
import store from "@/store/index";

export default class DataService extends ServiceBase {
  constructor() {
    super("data");
  }

  async getData(type: SolarDataType): Promise<number> {
    const siteId = store.getters["siteModule/getSelectedSiteId"];

    const data = await this.get<number>(
      this.urlBuilder.withFragment(siteId).withFragment(type.toString())
    );
    return data;
  }
}
