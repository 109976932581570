import { IDataModule } from "../parts/data-part/idata-module";
import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { UnitsParameterBase } from "../units/units-base.parameter";
import { ISolarModuleConfig } from "./isolar-module-config";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";

export abstract class SolarModuleConfigBase
  extends ModuleConfigBase
  implements ITitledModule, IDataModule, ISolarModuleConfig
{
  category = ModuleCategories.Solar;

  titlePart = new TitlePartConfig();
  dataPart = new DataPartConfig();
  abstract dataConverter: UnitsParameterBase;

  getContentParameters(): ParametersGroup[] {
    const completeDataGroup = this.dataPart.getParameterGroup();

    completeDataGroup.parameters.push(this.dataConverter);

    return [this.titlePart.getParameterGroup(), completeDataGroup];
  }
}
