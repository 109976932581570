









import { Component, Vue } from "vue-property-decorator";
import NotificationBar from "./components/notification-bar/NotificationBar.vue";
import { mapGetters } from "vuex";
import SitesService from "./services/sites.service";

@Component({
  components: { NotificationBar },
  computed: {
    ...mapGetters({
      selectedSite: "siteModule/getSelectedSiteId",
      colors: "colorsModule/getFormattedColors",
    }),
  },
})
export default class App extends Vue {
  private selectedSite!: string;
  private siteService = new SitesService();

  mounted() {
    if (this.selectedSite) {
      this.siteService
        .getSite(this.selectedSite)
        .then((s) => this.$store.dispatch("siteModule/loadSiteDetails", s));
    }
  }
}
