import { ModuleDTO } from "./modules/module.dto";
import { SceneBaseDTO } from "./scene-base.dto";

export class SceneDTO extends SceneBaseDTO {
  modules!: ModuleDTO[];

  constructor(init?: Partial<SceneDTO>) {
    super();
    Object.assign(this, init);
  }
}
