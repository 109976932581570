import { MediaDTO } from "../files/media.dto";

export class SceneIdDTO {
  id!: string;

  constructor(init?: Partial<SceneIdDTO>) {
    Object.assign(this, init);
  }
}

export abstract class SceneBaseDTO extends SceneIdDTO {
  title!: string;
  medias!: MediaDTO[];
  modificationDate!: string;
  backgroundType!: string;
  backgroundValue!: string;

  constructor(init?: Partial<SceneBaseDTO>) {
    super(init);
    Object.assign(this, init);
  }
}
