import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";

export class SliderParameterConfig extends ParameterBase<number> {
  parameterType = ParameterTypes.Slider;
  min: number;
  max: number;
  step: number;

  constructor(
    initialValue: number,
    name: ParameterNames,
    min: number,
    max: number,
    step: number
  ) {
    super(initialValue, name);
    this.min = min;
    this.max = max;
    this.step = step;
  }

  loadValue(value: string): void {
    this.value = parseInt(value);
  }
}
