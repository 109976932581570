import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParametersGroup } from "../parameters-group";
import {
  FileParameterConfig,
  ParameterNames,
} from "@/models/modules/parameters";
import { Media } from "@/models/files/media.model";
import { loadMediaToElement } from "@/utils/media.utils";

export class ImageModuleConfig extends ModuleConfigBase {
  type = ModuleTypes.Image;
  category = ModuleCategories.Other;

  image = new FileParameterConfig("", ParameterNames.Image);

  constructor() {
    super();

    this.loadParameters([
      {
        name: ParameterNames.BackgroundColor,
        value: "#FFFFFF00",
      },
      {
        name: ParameterNames.Elevation,
        value: "0",
      },
    ]);
  }

  getContentParameters(): ParametersGroup[] {
    return [
      new ParametersGroup("components.configMenu.groups.data", [this.image]),
    ];
  }

  public loadMedias(medias: Media[]): void {
    loadMediaToElement(this.image, medias);
  }
}
