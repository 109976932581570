import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";
import { IMediaElement } from "@/models/background/imedia-element";

export class FileParameterConfig
  extends ParameterBase<string>
  implements IMediaElement
{
  parameterType = ParameterTypes.File;
  mediaUrl = "";

  loadValue(value: string): void {
    this.value = value;
  }
}
