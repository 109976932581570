import { ParameterBase } from "../../models/modules/parameters/parameter-base";

export class ParametersGroup {
  title: string;
  parameters: ParameterBase<unknown>[];

  constructor(title: string, parameters: ParameterBase<unknown>[]) {
    this.title = title;
    this.parameters = parameters;
  }
}
