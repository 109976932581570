import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { UnitsParameterBase } from "../units-base.parameter";
import { PowerEnum } from "./power.enum";

export class PowerUnitsParameter extends UnitsParameterBase {
  constructor() {
    super(ParameterNames.WConverter);
  }

  getUnits(): string[] {
    return [PowerEnum.MW, PowerEnum.kW, PowerEnum.W];
  }
}
