import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParametersGroup } from "../parameters-group";

export class ColorModuleConfig extends ModuleConfigBase {
  type = ModuleTypes.Color;
  category = ModuleCategories.Other;

  getContentParameters(): ParametersGroup[] {
    return [];
  }
}
