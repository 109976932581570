import axios from "axios";
import Config from "../constants/config";
import { UrlBuilder } from "./url-builder";
import { NotificationLevel } from "@/models/notification-level.enum";
import Vue from "vue";
import { i18n } from "@/vue-plugins/i18n";
import store from "@/store";

export abstract class ServiceBase {
  private readonly baseUri: string;

  get urlBuilder() {
    return new UrlBuilder(this.baseUri);
  }

  protected constructor(urlFragment: string) {
    this.baseUri = `${Config.API}/${urlFragment}`;
  }

  async get<T>(builder: UrlBuilder): Promise<T> {
    try {
      const result = await axios.get(builder.toString());

      this.displaySuccessMessage(builder);

      return result.data;
    } catch (error) {
      this.displayErrorMessage(builder);

      throw error;
    }
  }

  async post<TSent, TReceived>(
    builder: UrlBuilder,
    payload: TSent
  ): Promise<TReceived> {
    try {
      const result = await axios.post(builder.toString(), payload);

      this.displaySuccessMessage(builder);

      return result.data;
    } catch (error) {
      this.displayErrorMessage(builder);

      throw error;
    }
  }

  async put<TSent, TReceived>(
    builder: UrlBuilder,
    payload: TSent
  ): Promise<TReceived> {
    try {
      const result = await axios.put(builder.toString(), payload);

      this.displaySuccessMessage(builder);

      return result.data;
    } catch (error) {
      this.displayErrorMessage(builder);

      throw error;
    }
  }

  async delete(builder: UrlBuilder): Promise<void> {
    try {
      await axios.delete(builder.toString());

      this.displaySuccessMessage(builder);
    } catch (error) {
      this.displayErrorMessage(builder);

      throw error;
    }
  }

  private displaySuccessMessage(builder: UrlBuilder): void {
    this.displayMessage(builder.successMessageKey, NotificationLevel.success);
  }

  private displayErrorMessage(builder: UrlBuilder): void {
    this.displayMessage(builder.errorMessageKey, NotificationLevel.error);
  }

  private displayMessage(messageKey: string, level: NotificationLevel): void {
    if (!messageKey) {
      return;
    }

    Vue.prototype.$notifier.showMessage(i18n.tc(messageKey), level);
  }
}
