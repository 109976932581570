import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { UnitsParameterBase } from "../units-base.parameter";
import { EnergyEnum } from "./energy.enum";

export class EnergyUnitsParameter extends UnitsParameterBase {
  constructor() {
    super(ParameterNames.WhConverter);
  }

  getUnits(): string[] {
    return [EnergyEnum.MWh, EnergyEnum.kWh, EnergyEnum.Wh];
  }
}
