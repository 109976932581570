import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";
import { ParameterNames } from "./parameter-names.enum";

export class ScreenRelativeParameterConfig extends ParameterBase<number> {
  parameterType = ParameterTypes.ScreenRelative;

  useWidth!: boolean;

  get value(): number {
    return this._value;
  }

  set value(newValue: number) {
    this._value = newValue < 0 ? 0 : newValue;
  }

  constructor(initialValue: number, name: ParameterNames, useWidth: boolean) {
    super(initialValue, name);
    this.useWidth = useWidth;
  }

  loadValue(value: string): void {
    this.value = parseFloat(value);
  }
}
