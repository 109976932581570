import {
  ColorParameterConfig,
  OptionParameterConfig,
  SliderParameterConfig,
  ParameterNames,
} from "@/models/modules/parameters";
import { ParametersGroup } from "../../parameters-group";
import { IParametrablePart } from "../iparametrable-part";

export class DataPartConfig implements IParametrablePart {
  contentFontSize = new SliderParameterConfig(
    70,
    ParameterNames.ContentFontSize,
    1,
    100,
    1
  );
  contentFontColor = new ColorParameterConfig(
    "#424242FF",
    ParameterNames.ContentFontColor
  );
  contentAlignment = new OptionParameterConfig(
    "center",
    ParameterNames.ContentAlignment
  );

  getParameterGroup(): ParametersGroup {
    return new ParametersGroup("components.configMenu.groups.data", [
      this.contentFontSize,
      this.contentFontColor,
      this.contentAlignment,
    ]);
  }
}
