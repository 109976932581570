import { getConfig } from "@/components/modules/modules.util";
import { IModuleConfigBase } from "@/models/modules/imodule-config-base";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";
import { ParameterHolder } from "@/models/modules/parameters/parameter-holder";
import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { BackgroundConfig } from "@/models/background/background-config.model";
import { BackgroundTypes } from "@/models/background/background.enum";
import { Scene } from "@/models/scenes/scene.model";
import { LightSceneDTO } from "../scenes/light-scene.dto";
import { LightModuleDTO } from "../scenes/modules/light-module.dto";
import { ModuleParameterDTO } from "../scenes/modules/module-parameters.dto";
import { ModuleDTO } from "../scenes/modules/module.dto";
import { SceneDTO } from "../scenes/scene.dto";
import { SceneBaseDTO } from "../scenes/scene-base.dto";

export function getLightSceneFromDTO(scene: LightSceneDTO): Scene {
  return new Scene({
    id: scene.id,
    title: scene.title,
    modificationDate: scene.modificationDate,
    modules: scene.modules.map((m) => getModuleFromDTO(m)),
    background: getBackgroundFromDTO(scene),
    medias: scene.medias,
  });
}

export function getSceneFromDTO(scene: SceneDTO): Scene {
  return new Scene({
    id: scene.id,
    title: scene.title,
    modules: scene.modules.map((m) =>
      getModuleFromDTO(
        m,
        m.parameters.map((p) => getParameterFromDTO(p))
      )
    ),
    background: getBackgroundFromDTO(scene),
    medias: scene.medias,
  });
}

export function getSceneAsDTO(scene: Scene): SceneDTO {
  return new SceneDTO({
    id: scene.id,
    title: scene.title,
    modules: scene.modules.map((m) => getModuleAsDTO(m)),
    backgroundType: scene.background.backgroundType,
    backgroundValue: scene.background.value,
    medias: [],
  });
}

function getBackgroundFromDTO(scene: SceneBaseDTO): BackgroundConfig {
  return new BackgroundConfig({
    backgroundType:
      BackgroundTypes[scene.backgroundType as keyof typeof BackgroundTypes],
    value: scene.backgroundValue,
  });
}

function getModuleFromDTO(
  moduleDTO: LightModuleDTO,
  extraParams: ParameterHolder[] = []
): IModuleConfigBase {
  const parameters: ParameterHolder[] = [
    {
      name: ParameterNames.PosX,
      value: moduleDTO.posX.toString(),
    },
    {
      name: ParameterNames.PosY,
      value: moduleDTO.posY.toString(),
    },
    {
      name: ParameterNames.Width,
      value: moduleDTO.width.toString(),
    },
    {
      name: ParameterNames.Height,
      value: moduleDTO.height.toString(),
    },
    {
      name: ParameterNames.ZIndex,
      value: moduleDTO.zIndex.toString(),
    },
    {
      name: ParameterNames.Elevation,
      value: moduleDTO.elevation.toString(),
    },
    {
      name: ParameterNames.BackgroundColor,
      value: moduleDTO.backgroundColor.toString(),
    },
    ...extraParams,
  ];

  return getConfig(
    ModuleTypes[moduleDTO.type as keyof typeof ModuleTypes],
    parameters
  );
}

function getModuleAsDTO(module: IModuleConfigBase): ModuleDTO {
  const parameters = module.getParameters();

  return new ModuleDTO({
    type: module.type,
    posX: getAndRemoveParameter(parameters, ParameterNames.PosX) ?? 0,
    posY: getAndRemoveParameter(parameters, ParameterNames.PosY) ?? 0,
    width: getAndRemoveParameter(parameters, ParameterNames.Width) ?? 0,
    height: getAndRemoveParameter(parameters, ParameterNames.Height) ?? 0,
    zIndex: getAndRemoveParameter(parameters, ParameterNames.ZIndex),
    elevation: getAndRemoveParameter(parameters, ParameterNames.Elevation),
    backgroundColor: getAndRemoveParameter(
      parameters,
      ParameterNames.BackgroundColor
    ),
    parameters: parameters.map((p) => getParameterAsDTO(p)),
  });
}

function getParameterAsDTO(parameter: ParameterBase<any>): ModuleParameterDTO {
  return new ModuleParameterDTO({
    type: parameter.name,
    value: parameter.value.toString(),
  });
}

function getParameterFromDTO(parameter: ModuleParameterDTO): ParameterHolder {
  return {
    name: ParameterNames[parameter.type as keyof typeof ParameterNames],
    value: parameter.value,
  };
}

function getAndRemoveParameter(
  parameters: ParameterBase<any>[],
  name: ParameterNames
): any | undefined {
  const index = parameters.findIndex((p) => p.name === name);
  let value = undefined;

  if (index > -1) {
    value = parameters[index].value;
    parameters.splice(index, 1);
  }

  return value;
}
