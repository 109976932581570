import {
  ColorParameterConfig,
  OptionParameterConfig,
  SliderParameterConfig,
  ParameterNames,
} from "@/models/modules/parameters";
import Config from "@/constants/config";
import { ParametersGroup } from "../../parameters-group";
import { IParametrablePart } from "../iparametrable-part";

export class TitlePartConfig implements IParametrablePart {
  fontSize = new SliderParameterConfig(
    35,
    ParameterNames.TitleFontSize,
    1,
    100,
    1
  );

  color = new ColorParameterConfig(
    Config.PrimaryColor,
    ParameterNames.TitleFontColor
  );

  alignment = new OptionParameterConfig(
    "center",
    ParameterNames.TitleAlignment
  );

  indentation = new SliderParameterConfig(
    0,
    ParameterNames.TitleIndentation,
    0,
    100,
    5
  );

  getParameterGroup(): ParametersGroup {
    return new ParametersGroup("components.configMenu.groups.title", [
      this.fontSize,
      this.color,
      this.alignment,
      this.indentation,
    ]);
  }
}
