import { Site, SiteScene } from "@/models/sites/site.model";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";
import { RightLevel } from "@/models/right-level.enum";
import { DefaultSiteElement } from "@/models/default-site-element.interface";

const lastSiteId = localStorage.getItem("lastSiteId");

export interface SiteState {
  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  selectedSiteDefaultElement: DefaultSiteElement | undefined;
  canEditSite: boolean;
}

export const state: SiteState = {
  selectedSiteId: lastSiteId ?? undefined,
  selectedSiteName: "",
  selectedSiteDefaultElement: undefined,
  canEditSite: false,
};

const actions: ActionTree<SiteState, RootState> = {
  selectSite({ commit }, site: Site) {
    commit("setSelectedSiteId", site.id);
    localStorage.setItem("lastSiteId", site.id);
  },
  loadSiteDetails({ state, dispatch, commit }, site: SiteScene) {
    state.selectedSiteName = site.name;
    state.canEditSite = site.rightLevel !== RightLevel.view;

    dispatch("changeDefaultElement", {
      id: site.defaultElementId ?? "",
      type: site.defaultElementType ?? undefined,
    });
    commit("dataModule/setFetchInterval", site.fetchInterval, { root: true });
    dispatch("dataModule/resetDataUpdating", true, { root: true });
  },
  changeDefaultElement({ commit }, defaultElement: DefaultSiteElement) {
    commit("setDefaultElement", defaultElement);
  },
  onLogout() {
    localStorage.removeItem("lastSiteId");
  },
};

const mutations: MutationTree<SiteState> = {
  setSelectedSiteId(state, siteId: string) {
    state.selectedSiteId = siteId;
  },
  setDefaultElement(state, defaultElement: DefaultSiteElement) {
    state.selectedSiteDefaultElement = defaultElement;
  },
};

const getters: GetterTree<SiteState, RootState> = {
  getSelectedSiteId(state): string {
    return state.selectedSiteId || "";
  },
  getSelectedSiteName(state): string {
    return state.selectedSiteName || "";
  },
  canEditSite(state): boolean {
    return state.canEditSite;
  },
  getSiteDefaultElement(state): DefaultSiteElement | undefined {
    return state.selectedSiteDefaultElement;
  },
};

export const profile: Module<SiteState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
