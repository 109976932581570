import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";

export class SelectionParameterConfig extends ParameterBase<string> {
  parameterType = ParameterTypes.Selection;
  values: string[];

  constructor(initialValue: string, name: ParameterNames, values: string[]) {
    super(initialValue, name);
    this.values = values;
  }

  loadValue(value: string): void {
    this.value = value;
  }
}
