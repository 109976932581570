import { SelectionParameterConfig } from "@/models/modules/parameters";
import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { AUTO_UNIT } from "./units-constants";

export abstract class UnitsParameterBase extends SelectionParameterConfig {
  constructor(parameterName: ParameterNames) {
    super(AUTO_UNIT, parameterName, [AUTO_UNIT]);

    this.values.push(...this.getUnits());
  }

  abstract getUnits(): string[];
}
