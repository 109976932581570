export class UrlBuilder {
  private baseUrl: string;
  private fragments: string[] = [];
  private queryParams: Record<string, string> = {};

  successMessageKey = "";
  errorMessageKey = "";

  constructor(base: string) {
    this.baseUrl = base;
  }

  withFragment(fragment: string): UrlBuilder {
    this.fragments.push(fragment);

    return this;
  }

  withQueryParameter(name: string, value: string): UrlBuilder {
    this.queryParams[name] = value;
    return this;
  }

  withOptionalQueryParameter(
    name: string,
    value: string,
    shouldBeAdded: boolean
  ): UrlBuilder {
    return shouldBeAdded ? this.withQueryParameter(name, value) : this;
  }

  withLimit(limit: number): UrlBuilder {
    return this.withOptionalQueryParameter(
      "limit",
      limit.toString(),
      limit > 0
    );
  }

  withSuccessMessage(key: string): UrlBuilder {
    this.successMessageKey = key;
    return this;
  }

  withErrorMessage(key: string): UrlBuilder {
    this.errorMessageKey = key;
    return this;
  }

  toString(): string {
    let url = this.baseUrl;

    if (this.fragments.length) {
      url += `/${this.fragments.join("/")}`;
    }

    const query = Object.entries(this.queryParams)
      .map(([name, value]) => `${name}=${encodeURIComponent(value)}`)
      .join("&");

    return query ? `${url}?${query}` : url;
  }
}
