import { IDataModule } from "../parts/data-part/idata-module";
import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ModuleCategories } from "@/models/modules/module-categories.enum";

export class TreeModuleConfig
  extends ModuleConfigBase
  implements ITitledModule, IDataModule
{
  type = ModuleTypes.Tree;
  category = ModuleCategories.Solar;

  titlePart = new TitlePartConfig();
  dataPart = new DataPartConfig();

  getContentParameters(): ParametersGroup[] {
    return [
      this.titlePart.getParameterGroup(),
      this.dataPart.getParameterGroup(),
    ];
  }
}
