import { LoginUser } from "@/models/user/login-user.model";
import { RefreshedTokens, RefreshToken } from "@/models/user/refresh-token";
import { ServiceBase } from "./service-base";
import { RequestPasswordResetDTO } from "@/dto/login/request-password-reset.dto";
import { ResetPasswordDTO } from "@/dto/login/password-reset.dto";

export default class AuthenticationService extends ServiceBase {
  constructor() {
    super("authentication");
  }

  async login(user: LoginUser): Promise<any> {
    const data = await this.post<LoginUser, any>(
      this.urlBuilder
        .withFragment("authenticate")
        .withErrorMessage("services.authentication.loginFailed"),
      user
    );

    return data;
  }

  async refresh(token: RefreshToken): Promise<RefreshedTokens> {
    const data = await this.post<RefreshToken, RefreshedTokens>(
      this.urlBuilder.withFragment("refresh"),
      token
    );

    return data;
  }

  async requestPasswordReset(resetMail: string): Promise<void> {
    const resetRequest = new RequestPasswordResetDTO(resetMail);

    await this.post(
      this.urlBuilder
        .withFragment("requestreset")
        .withErrorMessage("services.authentication.resetPasswordFailed"),
      resetRequest
    );
  }

  async resetPassword(password: string, token: string): Promise<void> {
    const resetPassword = new ResetPasswordDTO(password, token);

    await this.post(
      this.urlBuilder
        .withFragment("reset")
        .withErrorMessage("services.authentication.resetPasswordFailed"),
      resetPassword
    );
  }
}
