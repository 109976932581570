import {
  getIcon,
  ParameterNames,
} from "@/models/modules/parameters/parameter-names.enum";
import { ParameterTypes } from "./parameter-types.enum";

export abstract class ParameterBase<T> {
  protected _value: T;
  title: string;
  name: ParameterNames;
  abstract parameterType: ParameterTypes;

  get icon(): string {
    return getIcon(this.name);
  }

  get value(): T {
    return this._value;
  }

  set value(newValue: T) {
    this._value = newValue;
  }

  constructor(initialValue: T, name: ParameterNames) {
    this.title = `components.configMenu.parameters.${name}`;
    this._value = initialValue;
    this.name = name;
  }

  abstract loadValue(value: string): void;
}
