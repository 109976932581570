import {
  ColorParameterConfig,
  NumberParameterConfig,
  ParameterNames,
} from "@/models/modules/parameters";
import { ParametersGroup } from "../../parameters-group";
import { IParametrablePart } from "../iparametrable-part";
import Config from "@/constants/config";

export class ProgressPartConfig implements IParametrablePart {
  mainColor = new ColorParameterConfig(
    Config.PrimaryColor,
    ParameterNames.MainProgressColor
  );
  secondColor = new ColorParameterConfig(
    "#e6e9f0",
    ParameterNames.SecondProgressColor
  );
  progressWidth = new NumberParameterConfig(20, ParameterNames.ProgressWidth);
  textFontSize = new NumberParameterConfig(35, ParameterNames.TextFontSize);
  textFontColor = new ColorParameterConfig(
    Config.PrimaryColor,
    ParameterNames.TextFontColor
  );

  getParameterGroup(): ParametersGroup {
    return new ParametersGroup("components.configMenu.groups.data", [
      this.textFontSize,
      this.textFontColor,
      this.mainColor,
      this.secondColor,
      this.progressWidth,
    ]);
  }
}
