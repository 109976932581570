import { ParameterNames } from "@/models/modules/parameters/parameter-names.enum";
import { UnitsParameterBase } from "../units-base.parameter";
import { CarbonEnum } from "./carbon.enum";

export class CarbonUnitsParameter extends UnitsParameterBase {
  constructor() {
    super(ParameterNames.WhConverter);
  }

  getUnits(): string[] {
    return [CarbonEnum.T, CarbonEnum.kg, CarbonEnum.g];
  }
}
