import { RefreshedTokens } from "@/models/user/refresh-token";
import { TokenUser } from "@/models/user/token-user.model";
import router from "@/router";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

const refreshToken = localStorage.getItem("refreshToken");
const userName = localStorage.getItem("userName");

export interface UserState {
  token: string | undefined;
  refreshToken: string | undefined;
  userName: string | undefined;
}

export const state: UserState = {
  token: undefined,
  refreshToken: refreshToken ?? undefined,
  userName: userName ?? undefined,
};

const actions: ActionTree<UserState, RootState> = {
  loadUser({ state }, name: string) {
    state.userName = name;
    localStorage.setItem("userName", state.userName);
  },
  successLogin({ dispatch }, user: TokenUser) {
    dispatch("refreshTokens", user.refreshedTokens);
    dispatch("loadUser", user.name);
  },
  refreshTokens({ state }, newTokens: RefreshedTokens) {
    state.token = newTokens.accessToken;
    state.refreshToken = newTokens.refreshToken;

    localStorage.setItem("refreshToken", state.refreshToken);
  },
  successLogout({ state, dispatch }) {
    state.token = undefined;
    state.refreshToken = undefined;
    localStorage.removeItem("refreshToken");

    dispatch("siteModule/onLogout", undefined, { root: true });

    router.push("/login");
  },
};

const getters: GetterTree<UserState, RootState> = {
  jwtToken(state): string | undefined {
    return state.token;
  },
  refreshToken(state): string | undefined {
    return state.refreshToken;
  },
  isLoggedIn(state): boolean {
    return !!state.token || !!state.refreshToken;
  },
  getUserName(state): string {
    return state.userName ?? "";
  },
};

const mutations: MutationTree<UserState> = {};

export const profile: Module<UserState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
