import { ITitledModule } from "../parts/title-part/ititled-module";
import { ParametersGroup } from "../parameters-group";
import { ProgressPartConfig } from "../parts/progress-part/progress-part.config";
import { TitlePartConfig } from "../parts/title-part/title-part.config";
import { IProgressModule } from "../parts/progress-part/iprogress-module";
import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";

export abstract class TitledProgressModuleConfigBase
  extends ModuleConfigBase
  implements ITitledModule, IProgressModule
{
  category = ModuleCategories.Solar;
  titlePart = new TitlePartConfig();
  progressPart = new ProgressPartConfig();

  getContentParameters(): ParametersGroup[] {
    return [
      this.titlePart.getParameterGroup(),
      this.progressPart.getParameterGroup(),
    ];
  }
}
