export enum ParameterTypes {
  Number = "number",
  Color = "color",
  Slider = "slider",
  Option = "option",
  Selection = "selection",
  ZIndex = "zindex",
  Text = "text",
  File = "file",
  ScreenRelative = "screenrelative",
}
