import { AllTimeEnergyModuleConfig } from "./all-time-energy-module/all-time-energy-module.config";
import { AutoConsumptionModuleConfig } from "./auto-consumption-module/auto-consumption-module.config";
import { AutoProductionModuleConfig } from "./auto-production-module/auto-production-module.config";
import { BatteryModuleConfig } from "./battery-module/battery-module.config";
import { CarbonModuleConfig } from "./carbon-module/carbon-module.config";
import { ColorModuleConfig } from "./color-module/color-module.config";
import { DailyConsumptionConfig } from "./daily-consumption-module/daily-consumption-module.config";
import { DayEnergyModuleConfig } from "./day-energy-module/day-energy-module.config";
import { IModuleConfigBase } from "../../models/modules/imodule-config-base";
import { InstantPowerModuleConfig } from "./instant-power-module/instant-power-module.config";
import { MonthEnergyModuleConfig } from "./month-energy-module/month-energy-module.config";
import { TreeModuleConfig } from "./tree-module/tree-module.config";
import { YearEnergyModuleConfig } from "./year-energy-module/year-energy-module.config";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParameterHolder } from "@/models/modules/parameters/parameter-holder";
import { TextModuleConfig } from "./text-module/text-module.config";
import { ImageModuleConfig } from "./image-module/image-module.config";

export function getConfig(
  type: ModuleTypes,
  parameters: ParameterHolder[] = []
): IModuleConfigBase {
  const moduleConfig = getConfigFromType(type);
  moduleConfig.loadParameters(parameters);
  return moduleConfig;
}

function getConfigFromType(type: ModuleTypes): IModuleConfigBase {
  switch (type) {
    case ModuleTypes.InstantPower:
      return new InstantPowerModuleConfig();
    case ModuleTypes.DayEnergy:
      return new DayEnergyModuleConfig();
    case ModuleTypes.DailyConsumption:
      return new DailyConsumptionConfig();
    case ModuleTypes.MonthEnergy:
      return new MonthEnergyModuleConfig();
    case ModuleTypes.YearEnergy:
      return new YearEnergyModuleConfig();
    case ModuleTypes.AllTimeEnergy:
      return new AllTimeEnergyModuleConfig();
    case ModuleTypes.Carbon:
      return new CarbonModuleConfig();
    case ModuleTypes.Tree:
      return new TreeModuleConfig();
    case ModuleTypes.AutoConsumption:
      return new AutoConsumptionModuleConfig();
    case ModuleTypes.AutoProduction:
      return new AutoProductionModuleConfig();
    case ModuleTypes.Battery:
      return new BatteryModuleConfig();
    case ModuleTypes.Color:
      return new ColorModuleConfig();
    case ModuleTypes.Text:
      return new TextModuleConfig();
    case ModuleTypes.Image:
      return new ImageModuleConfig();
    default:
      break;
  }

  throw new Error("Module type not implemented yet.");
}
