import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";

export class ColorParameterConfig extends ParameterBase<string> {
  parameterType = ParameterTypes.Color;

  loadValue(value: string): void {
    this.value = value;
  }
}
