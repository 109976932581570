export enum ParameterNames {
  SceneBackgroundColor = "sceneBackgroundColor",
  BackgroundColor = "backgroundColor",
  TitleFontSize = "titleFontSize",
  TitleFontColor = "titleFontColor",
  TitleAlignment = "titleAlignment",
  TitleIndentation = "titleIndentation",
  Width = "width",
  Height = "height",
  PosX = "posX",
  PosY = "posY",
  ZIndex = "zIndex",
  Elevation = "elevation",
  ContentFontSize = "contentFontSize",
  ContentFontColor = "contentFontColor",
  ContentAlignment = "contentAlignment",
  WConverter = "wConverter",
  WhConverter = "whConverter",
  TextFontSize = "textFontSize",
  TextFontColor = "textFontColor",
  MainProgressColor = "mainProgressColor",
  ProgressWidth = "progressWidth",
  SecondProgressColor = "secondProgressColor",
  TextContent = "textContent",
  Image = "image",
}

export function getIcon(parameter: ParameterNames): string {
  switch (parameter) {
    case ParameterNames.Width:
      return "mdi-arrow-left-right";

    case ParameterNames.Height:
      return "mdi-arrow-up-down";

    case ParameterNames.PosX:
      return "mdi-arrow-right";

    case ParameterNames.PosY:
      return "mdi-arrow-down";

    case ParameterNames.ZIndex:
      return "mdi-arrange-bring-to-front";

    case ParameterNames.SceneBackgroundColor:
    case ParameterNames.BackgroundColor:
    case ParameterNames.TitleFontColor:
    case ParameterNames.ContentFontColor:
    case ParameterNames.TextFontColor:
    case ParameterNames.MainProgressColor:
    case ParameterNames.SecondProgressColor:
      return "mdi-format-color-fill";

    case ParameterNames.TextFontSize:
    case ParameterNames.TitleFontSize:
    case ParameterNames.ContentFontSize:
      return "mdi-format-size";

    case ParameterNames.TitleAlignment:
    case ParameterNames.ContentAlignment:
      return "mdi-format-align-left";

    case ParameterNames.ProgressWidth:
      return "mdi-diameter-outline";

    case ParameterNames.TitleIndentation:
      return "mdi-format-indent-increase";

    case ParameterNames.WhConverter:
    case ParameterNames.WConverter:
      return "mdi-ruler";

    case ParameterNames.TextContent:
      return "mdi-format-text";

    case ParameterNames.Elevation:
      return "mdi-box-shadow";

    case ParameterNames.Image:
      return "mdi-image-edit";

    default:
      return "REPLACEME";
  }
}
