import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";

export class TextParameterConfig extends ParameterBase<string> {
  parameterType = ParameterTypes.Text;

  loadValue(value: string): void {
    this.value = value;
  }
}
