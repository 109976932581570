import { LightModuleDTO } from "./light-module.dto";
import { ModuleParameterDTO } from "./module-parameters.dto";

export class ModuleDTO extends LightModuleDTO {
  parameters!: ModuleParameterDTO[];

  constructor(init?: Partial<ModuleDTO>) {
    super();
    Object.assign(this, init);
  }
}
