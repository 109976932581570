import { ParameterTypes } from "@/models/modules/parameters/parameter-types.enum";
import { ParameterBase } from "@/models/modules/parameters/parameter-base";

export class NumberParameterConfig extends ParameterBase<number> {
  parameterType = ParameterTypes.Number;

  get value(): number {
    return this._value;
  }

  set value(newValue: number) {
    this._value = newValue < 0 ? 0 : newValue;
  }

  loadValue(value: string): void {
    this.value = parseFloat(value);
  }
}
